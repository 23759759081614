require('slick-carousel');
(function($, window){
    $('.imagecarousel-element__carousel').each(function(){
        let parent = $(this);
        let slider = parent.find('.carousel');
        let carousel = slider.slick({
            centerMode:true,
            centerPadding:'5%',
            slidesToShow:1,
            arrows:false
        });

        $('.next a').click(function(e){
            e.preventDefault();
        });
        $('.prev a').click(function(e){
            e.preventDefault();
        });
    });
})(jQuery, window);
