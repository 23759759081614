(function($, window){
    $('.mobile-nav').click(function (e) {
        e.preventDefault()
        $('header .close').toggleClass('active')
        $('header .hamburger').toggleClass('active')
        $('header nav ul').toggleClass('active');
        $('header h1.mobile-only').toggleClass('active');
    });

    $(window).resize(function () {
        var windowWidth = $(window).outerWidth()
        if (windowWidth > 1150) {
            $('header .close').removeClass('active')
            $('header .hamburger').addClass('active')
            $('header nav ul').removeClass('active');
            $('header h1.mobile-only').removeClass('active');
            $('header nav ul').css('transition', 'transform 0s')
        } else {
            $('header nav ul.active a.collection').removeClass('current');
            setTimeout(function () {
                $('header nav ul').css('transition', 'transform .3s')
            }, 300)
        }
    });

    $(document.body).on('click', 'header nav ul:not(.active) a.collection', function(e){
        e.preventDefault();
        $(this).toggleClass('current');
        $('header nav .bottle-carousel').toggleClass('active');
    });
})(jQuery, window);
