import ModalVideo from 'modal-video';

(function($, window){
    $('.js-modal-video').each(function(){
        let btn = $(this);
        let mv = {};
        if(btn.data('type') == 'Vimeo'){
             mv = new ModalVideo(btn, {channel: 'vimeo'});
        } else {
            mv = new ModalVideo(btn, {channel: 'youtube'});
        }
    });

    $('.playme').on('click', function(e){
        e.preventDefault();
        var btn = $(this);
        var video = btn.parent().find('video')[0];

        btn.fadeOut('fast', function(){
            video.play();
        });
    });
})(jQuery, window);
