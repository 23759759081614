(function($, window){

  $(window).ready(function(){
      $('input[type="text"],input[type="number"],input[type="email"],input[type="tel"],input[type="phone"],input[type="date"],input[type="password"], textarea, select').each(function(){
          let $parent = $(this).parent();

          if($(this).hasClass('textarea')) {
            $parent.find('label').addClass('textarea-active')
            $parent.siblings('label').addClass('textarea-active')
          }

          if($(this).val() != ''){
              if($(this).hasClass('dropdown')){
                  $parent = $parent.parent();
              }
              $parent.find('label').css('transition', 0)
              $parent.find('label').addClass('input-active')
              $parent.siblings('label').css('transition', 0)
              $parent.siblings('label').addClass('input-active')
          }
          $(this).addClass('input-active');
      });

      $(document.body).on('keyup blur focus focusin focusout', 'input[type="text"],input[type="number"],input[type="email"],input[type="tel"],input[type="phone"],input[type="date"],input[type="password"], select', function(e){
          var $this = $(this),
              $parent = $this.parent();
              if($this.hasClass('dropdown')){
                  $parent = $parent.parent();
              }
          $(this).addClass('input-active');
          if (e.type == 'keyup') {
            $parent.find('label').addClass('input-active')
            $parent.siblings('label').addClass('input-active')
          }
          else if (e.type == 'blur' || e.type == "focusout") {
            if( $this.val() == '' ) {
                $parent.find('label').removeClass('input-active')
                $parent.siblings('label').removeClass('input-active')
                $(this).removeClass('input-active');
            } else {
                $parent.find('label').addClass('input-active')
                $parent.siblings('label').addClass('input-active')
            }
          }
          else if (e.type == 'focus' || e.type == 'focusin') {
            $parent.find('label').addClass('input-active')
            $parent.siblings('label').addClass('input-active')
          }
      });
  });

})(jQuery, window);
