(function($, window){

    $(document.body).on('click', 'footer a.shareit', function(e){
        e.preventDefault();

        FB.ui({
            method: 'send',
            link: $(this).attr('href'),
        });
    });

})(jQuery, window);
