(function($, window){

    let avail = true;

    const incrementCount = (holder) => {
        var count = holder.text();
        if(count.includes('m') || count.includes('k')){
            return;
        }

        if(count == ''){
            count = 0;
        }

        count = parseInt(count, 10);
        count++;
        holder.html(count+'<i class="fal fa-thumbs-up"></i>');
    };

    const decrementCount = (holder) => {
        var count = holder.text();
        if(count.includes('m') || count.includes('k')){
            return;
        }

        count = parseInt(count, 10);
        count--;
        holder.html(count+'<i class="fal fa-thumbs-up"></i>');
    };


    $(document.body).on('click', 'a.ajaxme', function(e){
        e.preventDefault();
        if(avail){
            avail = false;
            var link = $(this);
            link.addClass('loading');
            $.ajax({
                url:link.attr('href'),
                dataType: 'json',
                success: function(response){
                    if(response.success){
                        //Is there anything else to do?
                        if(link.hasClass('toggleme')){
                            link.toggleClass('toggle');
                            if(response.toggle){
                                link.find('span').text(link.data('toggle-true'));
                                incrementCount(link.find('em'));
                            } else {
                                link.find('span').text(link.data('toggle-false'));
                                decrementCount(link.find('em'));
                            }

                            avail = true;
                            link.removeClass('loading');
                        }
                    }
                },
                error: function(){
                    avail = true;
                    link.removeClass('loading');
                }
            });
        }
    });
})(jQuery, window);
