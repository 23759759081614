import './components/ajax-toggle.js';
import './components/dropdown-filter.js';
import './components/form-label-transform.js';
import './components/facebook-sdk.js';
import './components/collabassets.js';
import './components/footershare.js';
import './components/modalvideo.js';
import './components/image-carousel-block.js';
import './components/ad-block.js';
import './components/nav.js';

import AgeGate from './Vue/Components/AgeGate.vue';
import VeeValidate from 'vee-validate';

import { library, dom, icon } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight, faChevronLeft, faChevronDown, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { faThumbsUp, faTimes } from '@fortawesome/pro-light-svg-icons';

import AOS from 'aos';

library.add(faChevronRight, faChevronLeft, faPlusCircle, faThumbsUp, faTimes, faChevronDown);

dom.i2svg({ callback () {
    console.log('done rendering icons');
} });
dom.watch();

Vue.use(VeeValidate);

AOS.init();

const ageGateApp = new Vue({
  el: '#agegate',
  components: {
    AgeGate
  },
  render: h => h(AgeGate)
});

var oldWtbCallback = ejgWTB_callback;
ejgWTB_callback = function() {
    oldWtbCallback.apply(this);
    let submitBtn = document.querySelector('.wtbSubmit')
    let zipCode = $('#zipCode')

    $('#ejgWhereToBuy #submitBtnWrapper input').on('click', function(e){
        let label = 'In Stores';
        let dataLayer = window.dataLayer = window.dataLayer || [ ];
        dataLayer.push({
            event: 'findStoreSubmit',
            selectedType: label,
            selectedProduct: document.querySelector('#flavorDropDownWrapper .selectedTxt').innerText,
            formStatus: "Success"
        });
    });
}

$(document.body).on('click', '.gtag', (e) => {
    var link = $(e.currentTarget);

    gtag('event', link.data('action'), {
        'event_category':link.data('category'),
        'event_label':link.data('label')
    });
});
$(document.body).on('click', '[target="_blank"]', (e) => {
    var link = $(e.currentTarget);

    gtag('event', 'click', {
        'event_category':'Outbound Link',
        'event_label':link.attr('href')
    });
});
