<template>
    <div id="agegate-app">
        <dialog id="agegate-dialog" class="fixed">
            <div class="top-border"></div>
            <div class="agegate-form">
                <div class="wrap">
                    <img src="/images/ej-logo-white.svg" alt="" role="presentation" />
                    <h1 class="outline">Brandy</h1>
                    <p>You must be 21+ to&nbsp;enter</p>
                    <form class="date">
                        <div class="fields">
                            <div class="field">
                                <label class="sr-only" for="month">Birth Month</label>
                                <input id="month" type="number" class="month" v-model="month" name="month" @click="month=''" placeholder="MM" @keyup.enter="authorize" @keyup="nextFieldCheck(month, 0)" min="01" max="12" length="2" v-validate="'required|numeric|min:2|max:4'">
                            </div>
                            <div class="field">
                                <label class="sr-only" for="day">Birth Day</label>
                                <input id="day" type="number" class="day" v-model="day" name="day" @click="day=''" placeholder="DD" @keyup.enter="authorize" @keyup="nextFieldCheck(day, 1)"  min="01" max="31" length="2" v-validate="'required|numeric|min:2|max:4'">

                            </div>
                            <div class="field">
                                <label class="sr-only" for="year">Birth Year</label>
                                <input id="year" type="number" class="year" v-model="year" name="year" @click="year=''" placeholder="YYYY" @keyup.enter="authorize" @keyup="nextFieldCheck(year, 2)"  min="1900" max="2999" length="4" v-validate="'required|numeric|min:4|max:4'">
                            </div>
                        </div>
                    </form>
                    <label class="error" v-if="errors.first('month')">{{ errors.first('month') }}</label>
                    <label class="error" v-if="errors.first('day')">{{ errors.first('day') }}</label>
                    <label class="error" v-if="errors.first('year')">{{ errors.first('year') }}</label>
                    <div class="Actions">
                        <div class="btn">
                            <a href="#" @click.prevent="authorize">Confirm</a>
                        </div>
                    </div>
                    <h3 v-if="denied" class="error">Sorry, you must be 21+ to&nbsp;enter</h3>
                </div>
  			</div>
        </dialog>
    </div>
</template>
<script>
const Cookies = require('js-cookie');
const dialogPolyfill = require('dialog-polyfill');
let dialog = {};

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

import * as toastr from 'toastr';
toastr.options.positionClass = 'toast-top-left';

export default {
    data () {
        return {
            denied:false,
            month: '',
            day: '',
            year: ''
        }
    },
    methods: {
        authorize () {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    if (this.isDate21orMoreYearsOld(this.day, this.month, this.year)){
                        this.denied = false
                        Cookies.set('ofage', true, { expires: 7, path: '/' })
                        dialog.close()
                        let dataLayer = window.dataLayer = window.dataLayer || [ ];
                        dataLayer.push({
                            event:'ageGateEnter',
                            customerID:'anonToken'
                        });
                    } else {
                        this.denied = true
                    }
                } else {
                    this.denied = true
                }
            })
        },
        deny () {
            this.denied = true;
        },
        bornOnDate () {
            let now = new Date()
            now.setFullYear(now.getFullYear() - 21)
            this.month = now.getMonth() + 1
            this.day = now.getDate()
            this.year = now.getFullYear()
            // return monthNames[now.getMonth()] + ' ' + now.getDate() + ', ' + now.getFullYear()
        },
        isDate21orMoreYearsOld (day, month, year) {
            if(day != "" && month != "" && year != ""){
                return new Date(parseInt(year) + 21, parseInt(month) - 1, parseInt(day)) <= new Date()
            }

            return false
        },
        nextFieldCheck (v, i) {
            if(v.length == 2 && i < 2){
                if(i){
                    $('.year').focus();
                    $('.year').click();
                } else {
                    $('.day').focus();
                    $('.day').click();
                }
            } else if(v.length > 4 && i == 2){
                this.year = this.year.slice(0,4)
            }
        }
    },
    mounted () {
        dialog = document.getElementById('agegate-dialog');
        dialogPolyfill.registerDialog(dialog);
        if(!Cookies.get('ofage')){
            dialog.showModal();
            let dataLayer = window.dataLayer = window.dataLayer || [ ];
            dataLayer.push({
                event:'ageGateShown',
                customerID:'anonToken'
            });
            dialog.addEventListener('cancel', (event) => {
                event.preventDefault();
            });
        } else if($('.session-notification-wrap .notification').length){
            let notification = $('.session-notification-wrap .notification')
            let html = notification.html();

            switch(notification.data('type')){
                case 'success':
                    toastr.success(html);
                    break;
                case 'warning':
                    toastr.warning(html);
                    break;
                case 'info':
                    toastr.info(html);
                    break;
                case 'error':
                    toastr.error(html);
                    break;
                default:
                    toastr.info(html);
                    break;
            }
        }
    }
}
</script>
